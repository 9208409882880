import React from 'react';
import ComingSoon from './pages/comingSoon/comingSoon';
import './App.css'

function App() {
  return (
    <div className="app">
      <ComingSoon />
    </div>
  );
}

export default App; 